import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from "vuex";
import store from "@/store";
import { validateForms } from "@/common/lib/validate";
import { gridIcon, boxIcon, eyeIcon, eyeOffIcon, checkCircleIcon, userIcon, fileTextIcon, cableErrorIcon } from "@debionetwork/ui-icons";
import NavigationDrawer from "@/common/components/NavigationDrawer";
import Navbar from "@/common/components/Navbar.vue";
import maintenancePageLayout from "@/views/Dashboard/maintenancePageLayout";
import errorMessage from "@/common/constants/error-messages";
import localStorage from "@/common/lib/local-storage";
import { getUnlistedNotification } from "@/common/lib/notification";
import VueRouter from "@/router";
export default {
  name: "MainPage",
  mixins: [validateForms],
  components: {
    NavigationDrawer,
    Navbar,
    maintenancePageLayout
  },
  data: () => ({
    checkCircleIcon,
    eyeIcon,
    eyeOffIcon,
    cableErrorIcon,
    showModalError: false,
    showModalPassword: false,
    pageError: null,
    showPassword: false,
    success: false,
    error: null,
    password: null,
    navs: [{
      text: "Dashboard",
      disabled: false,
      active: false,
      route: "ga-dashboard",
      icon: gridIcon
    }, {
      text: "My Account",
      disabled: false,
      active: false,
      route: "ga-account",
      icon: userIcon
    }, {
      text: "My Services",
      disabled: false,
      active: false,
      route: "ga-services",
      icon: fileTextIcon
    }, {
      text: "Order History",
      disabled: false,
      active: false,
      route: "ga-orders",
      icon: boxIcon
    }]
  }),
  computed: {
    ...mapState({
      lastEventData: state => state.substrate.lastEventData,
      lastBlockData: state => state.substrate.lastBlockData,
      wallet: state => state.substrate.wallet,
      localListNotification: state => state.substrate.localListNotification,
      mnemonicData: state => state.substrate.mnemonicData
    }),
    computeNavs() {
      const setActive = name => {
        return this.$route.name === name || this.$route.meta.parent === name;
      };
      return this.navs.map(nav => ({
        ...nav,
        active: setActive(nav.route)
      }));
    },
    computeButtonActive() {
      return !/(\/customer\/request-test)/.test(this.$route.path);
    }
  },
  watch: {
    $route(val) {
      var _VueRouter$history, _VueRouter$history$cu;
      const query = VueRouter === null || VueRouter === void 0 ? void 0 : (_VueRouter$history = VueRouter.history) === null || _VueRouter$history === void 0 ? void 0 : (_VueRouter$history$cu = _VueRouter$history.current) === null || _VueRouter$history$cu === void 0 ? void 0 : _VueRouter$history$cu.query;
      if (val.meta.maintenance) this.pageError = true;else this.pageError = null;
      if (query.error) this.showModalError = true;
    },
    lastEventData: {
      handler: async function (event) {
        if (event === null) return;
        await getUnlistedNotification(parseInt(this.lastBlockData.replaceAll(",", "")));
      }
    }
  },
  async mounted() {
    if (!this.mnemonicData) this.showModalPassword = true;
    if (this.$route.meta.maintenance) this.pageError = true;
    await this.getAccount();
    await this.getListNotification();
  },
  rules: {
    password: [val => !!val || errorMessage.PASSWORD(8)]
  },
  methods: {
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    handlePageError(error) {
      this.pageError = error;
    },
    async getListNotification() {
      if (this.lastBlockData) {
        await this.$store.dispatch("substrate/getListNotification", {
          address: this.wallet.address,
          role: "analyst",
          block: this.lastBlockData
        });
      }
    },
    goToRequestTestPage() {
      this.$router.push({
        name: "customer-request-test"
      });
    },
    goToUploadEMR() {
      this.$router.push({
        name: "customer-emr-create"
      });
    },
    goToDashboard() {
      this.showModalError = false;
      this.$router.push({
        name: "dashboard",
        query: {}
      });
    },
    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    signOut() {
      this.$router.push({
        name: "landing-page"
      });
      const accounts = Object.keys(window.localStorage).filter(v => /account:/.test(v));
      accounts.forEach(a => {
        window.localStorage.removeItem(a);
      });
      localStorage.clear();
      this.clearAuth();
      this.clearWallet();
    },
    async handleSubmitPassword() {
      try {
        await this.wallet.unlock(this.password);
        await store.dispatch("substrate/getEncryptedAccountData", {
          password: this.password
        });
        this.success = true;
        setTimeout(() => {
          this.showModalPassword = false;
        }, 1300);
      } catch (e) {
        this.error = e;
      }
    },
    async getAccount() {
      const {
        GAAccount
      } = await this.$store.dispatch("substrate/getGAAccount");
      const formatNavs = status => {
        this.navs = this.navs.map(element => {
          if (element["text"] !== "Dashboard") return {
            ...element,
            disabled: status
          };else return element;
        });
      };
      if (!GAAccount || GAAccount && (GAAccount === null || GAAccount === void 0 ? void 0 : GAAccount.verificationStatus) !== "Verified") formatNavs(true);else if ((GAAccount === null || GAAccount === void 0 ? void 0 : GAAccount.verificationStatus) === "Verified") formatNavs(false);
    }
  }
};